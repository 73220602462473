<template>
  <section>
    <div class="main-graph">
      <div
        class="part"
        :style="getStyle(er, index)"
        
        v-for="(er, index) in engagement"
        :key="index"
        @mouseover="handleMouseOver(index)"
        @mouseleave="handleMouseOut(index)"
        :id="`er-${index}-${extra_key}`"
        >
        <b-tooltip :target="`er-${index}-${extra_key}`" positioning="bottom">{{ er.text }}</b-tooltip>
        <b-img v-if="engagement.findIndex(i => i.key === mark) === index" :src="require('@/assets/images/svg/arrow_down_graph.svg')" class="img-arrow-indicator"></b-img>
      </div>
    </div>
    <div class="d-flex justify-content-between mt-05">
      <p class="avenir-medium">{{ $t('tiktok.fair') }}</p>
      <p class="avenir-medium">{{ $t('tiktok.excellent') }}</p>
    </div>
  </section>
</template>

<script>

import {
  BTooltip,
  BImg,
} from 'bootstrap-vue';

export default {

  name: 'scaleRateSearch',
  components: { 
    BTooltip,
    BImg,
  },
  props: {
    mark: {
      type: String,
    },
    extra_key: {
      type: String,
      defaul: '0'
    }
  },
  data() {
    return {
      engagement: [
        { text: this.$t('tiktok.fair'), color: '#ec3232', flex_grow: 0.25, key: 'poor'},
        { text: this.$t('search_preview.engagement_nevel.fair').text, color: '#fa6e32', flex_grow: 0.15, key: 'fair'},
        { text: this.$t('search_preview.engagement_nevel.average').text, color: '#f4ab1d', flex_grow: 0.2, key: 'average' },
        { text: this.$t('search_preview.engagement_nevel.good').text, color: '#27AE60', flex_grow: 0.2, key: 'good' },
        { text: this.$t('search_preview.engagement_nevel.high').text, color: '#289155', flex_grow: 0.1, key: 'high' },
        { text: this.$t('tiktok.excellent'), color: '#8037DC', flex_grow: 0.1, key: 'excellent' },
      ],
      hovered_index: null,
    };
  },
  methods: {
    handleMouseOver(index) {
      this.hovered_index = index;
    },
    handleMouseOut() {
      this.hovered_index = null;
    },
    getStyle(er, index) {
      const index_of = this.engagement.findIndex(i => i.key === this.mark);

      return {
        flexGrow: er.flex_grow,
        backgroundColor: (this.mark === this.engagement.find(i => i.key === this.mark).key && index_of === index) || this.hovered_index === index  ? er.color : '#E2E8F0',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main-graph {
  width: 100%;
  height: 20px;
  display: flex;
  gap: 4px;

  .part {
    height: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    .img-arrow-indicator {
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 19px;
      height: 16px;
    }
  }
}
.mt-05 {
  margin-top: 0.5em;
}
</style>
